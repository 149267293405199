import React from 'react';
import { Link, graphql } from 'gatsby';
import { localizer } from '../util/localizer';

export default function ErrorPage(props) {
  const ctx = props.pageContext;
  const localized = localizer(ctx);
  const sitedata = props.data.siteYaml;
  const cfg = (props.location ? (props.location.state || {}) : {});
  return (
    <div className="p-8">
      <h1 className="my-2 p-8">{cfg.description}</h1>
      <h2 className="my-2 p-8">{sitedata.errorpage.appError}</h2>
      <div className="mt-40"><Link to={localized('/')} className="slide-btn">{sitedata.homelink}</Link></div>
    </div>
  );
};

export const query = graphql`
  query siteErrorQ($locale: String, $variation: String) {
    siteYaml(locale: {eq: $locale}, variation: {eq: $variation}) {
      id
      locale
      variation
      homelink
      errorpage {
        appError
      }
    }
  }`;
